import { render, staticRenderFns } from "./MenuSearch.vue?vue&type=template&id=060f9705&scoped=true"
import script from "./MenuSearch.vue?vue&type=script&lang=js"
export * from "./MenuSearch.vue?vue&type=script&lang=js"
import style0 from "./MenuSearch.vue?vue&type=style&index=0&id=060f9705&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "060f9705",
  null
  
)

export default component.exports