<template>
  <div
    v-if="modelState || internalModelEnder"
    class="platform-dialog"
    :class="{
      active: internalModelStarter,
      [variant]: !!variant,
      panel: panel
    }"
    @click="(e) => clickOutside(e)"
  >
    <transition :name="transitionName">
      <div
        v-if="internalModelStarter"
        class="platform-dialog-content"
        :class="{ shake: shake }"
        @click="(e) => clickOutside(e)"
      >
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    // does not allow clicking outside to close
    persistant: {
      type: Boolean,
      default: false
    },
    variant: {
      type: String,
      default: ""
    },
    // panel dialog (slides from left with full height)
    panel: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    modelState: false,
    internalModelStarter: false,
    internalModelEnder: false,
    scrollBlocked: false,
    shake: false
  }),

  computed: {
    transitionName() {
      return this.panel ? "panel" : "dialog";
    }
  },

  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (this.modelState != val) this.modelState = val;
      }
    },
    modelState: {
      async handler(val) {
        this.$emit("input", val);
        if (val) {
          this.blockScroll();
          await new Promise((r) => setTimeout(r, 100));
          this.internalModelStarter = true;
          this.internalModelEnder = true;
        } else if (this.scrollBlocked) {
          this.unblockScroll();
          this.internalModelStarter = false;
          await new Promise((r) => setTimeout(r, 500));
          this.internalModelEnder = false;
        }
      },
      immediate: true
    }
  },

  mounted() {
    document.addEventListener("keyup", (e) => {
      if (e.key == "Escape" && this.modelState) {
        this.modelState = false;
      }
    });
  },

  destroyed() {
    document.removeEventListener("keyup", (e) => {
      if (e.key == "Escape" && this.modelState) {
        this.modelState = false;
      }
    });
    this.unblockScroll();
  },

  methods: {
    clickOutside(e) {
      if (
        (e.target.classList.contains("platform-dialog") ||
          e.target.classList.contains("platform-dialog-content")) &&
        !this.persistant
      ) {
        this.modelState = false;
      } else if (
        (e.target.classList.contains("platform-dialog") ||
          e.target.classList.contains("platform-dialog-content")) &&
        this.persistant
      ) {
        this.$emit("persistantClickOutside");
        if (!this.shake) {
          this.shake = true;
          setTimeout(() => {
            this.shake = false;
          }, 500);
        }
      }
    },
    blockScroll() {
      this.scrollBlocked = true;
      document.body.style.overflow = "hidden";
      document.body.dataset.dialogOpen = 1;
    },
    unblockScroll() {
      this.scrollBlocked = false;
      document.body.style.overflow = "auto";
      document.body.dataset.dialogOpen = 0;
    }
  }
};
</script>

<style lang="sass">
.platform-dialog
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  z-index: 1000
  background: rgba(7, 19, 30, 0.6)
  display: flex
  align-items: center
  justify-content: center
  opacity: 0
  pointer-events: none
  transition: all 0.2s ease-in-out
  transition-delay: 0.1s
  backdrop-filter: blur(8px)
  .platform-dialog-content
    width: 100%
    height: 100%
    display: flex
    justify-content: center
    align-items: center
    transition: all 0.2s ease-in-out
    &.shake
      animation: shakeBig 0.38s cubic-bezier(.36,.07,.19,.97) both .12s
    .platform-card
      max-width: var(--dialog-card-width-small)
      max-height: 80vh
      &.expanded
        border-radius: 0 !important
        max-width: 100vw !important
        max-height: 100vh !important
      .actions
        // justify-content: flex-end !important

  &.soft
    backdrop-filter: blur(3px)

  &.active
    opacity: 1
    pointer-events: all
    transition-delay: 0s

  &.panel
    justify-content: flex-end
    backdrop-filter: blur(3px)
    background: rgba(0, 0, 0, 0.15)
    .platform-dialog-content
      width: 100%
      max-width: 450px
      height: 100vh
      max-height: 100vh
      padding: 0
      .platform-card
        height: 100%
        border-radius: 0 !important
        max-height: 100vh
        box-shadow: 0 0 100px -10px rgba(0,0,0,0.04)
        border-left: 1px solid rgba(0,0,0,0.05)
        .header
          min-height: var(--menu-height)


.dialog-enter-active, .dialog-leave-active, .panel-enter-active, .panel-leave-active
  transition: all .3s ease-in-out

.dialog-enter-active, .panel-enter-active
  transition-delay: 0.1s

.dialog-enter
  transform: translateY(50vh)
.dialog-leave-to
  transform: translateY(50vh)

.panel-enter
  transform: translateX(500px)
.panel-leave-to
  transform: translateX(500px)
</style>
