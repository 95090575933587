import { REPORT_DEFAULT_DATE_FORMAT } from "~/constants";
import momentTS from "moment-timezone";

const moment = require("moment-timezone");
export class DelayedPublication {
  constructor(date, time, timezone, status) {
    this.date = date;
    this.time = time;
    this.timezone = timezone;
    this.isDelayed = false;
    this.status = status;
  }

  // Setter method for DateTime
  setDateTime(newDateTime) {
    this.date = newDateTime;
  }

  hasPassedValidation() {
    if (this.date !== null && this.timezone !== "") {
      return true;
    }
    return false;
  }
  getISODate() {
    const momentDate = moment(this.date);
    /*
      Relabel the date from the browsers timezone into the new timezone
     */

    const newDate = moment.tz(
      {
        year: momentDate.year(),
        month: momentDate.month(),
        day: momentDate.date(),
        hour: momentDate.hour(),
        minute: momentDate.minute(),
        second: momentDate.second()
      },
      this.timezone
    );
    return newDate.toISOString();
  }

  // Setter method for Timezone
  setTimezone(newTimezone) {
    if (newTimezone != null) {
      // Find the underlying timezone
      const foundTimezone = VALID_DELAYED_PUBLICATION_TIMEZONES.find((tz) => {
        return (
          tz.abbreviation.includes(newTimezone) ||
          newTimezone.includes(tz.abbreviation)
        );
      });
      if (foundTimezone == null) {
      } else {
        this.timezone = foundTimezone.timezone;
      }
    }
  }

  setTimezoneFromDB(newTimezone) {
    this.timezone = newTimezone;
  }

  // Setter for the status, this will set the IsDelayed field
  setStatus(newStatus) {
    this.isDelayed = newStatus === DELAYED_PUBLICATION_DELAYED;
    this.status = newStatus;
    if (!this.isDelayed) {
      this.timezone = null;
    }
  }

  getTimeZonePopupDisplay() {
    const foundTimezone = VALID_DELAYED_PUBLICATION_TIMEZONES.find(
      (tz) => tz.timezone === this.timezone
    );
    if (typeof foundTimezone === "undefined") {
      // There are times when the timezone is now found in the provided default list VALID_DELAYED_PUBLICATION_TIMEZONES, where we have to set the users timezone and add it to the list first then recall this function
      // We need to create an entry with abbreviation given the current timezone either from the browser of preloaded from the change
      GetAbbreviationForGivenTimeZone(this.timezone);
      return this.getTimeZonePopupDisplay();
    } else {
      return foundTimezone.abbreviation;
    }
  }
}

export const DELAYED_PUBLICATION_IMMEDIATE = "Immediate";
export const DELAYED_PUBLICATION_DELAYED = "Delayed";

export class TimeZoneOption {
  constructor(name, shortHand, offset) {
    this.name = name;
    this.shortHand = shortHand;
    this.offset = offset;
  }
}

const VALID_DELAYED_PUBLICATION_TIMEZONES = [
  { timezone: "Australia/Sydney", name: "Sydney", abbreviation: "" },
  { timezone: "Pacific/Auckland", name: "Auckland", abbreviation: "" },
  { timezone: "US/Pacific", name: "US Pacific", abbreviation: "" },
  { timezone: "US/Central", name: "US Central", abbreviation: "" },
  { timezone: "US/Eastern", name: "US Eastern", abbreviation: "" },
  { timezone: "Africa/Johannesburg", name: "Johannesburg", abbreviation: "" }
];

/*
  For each timezone in the VALID_DELAYED_PUBLICATION_TIMEZONES array return strings with the following formatting
  AEDT (Sydney), or AEST (Sydney) if it's in standard time
 */
export function GetAllAbbreviationTimezonesForDate(date) {
  const options = [];
  for (const [index, ts] of VALID_DELAYED_PUBLICATION_TIMEZONES.entries()) {
    const abbreviation = getTimezoneAbbreviation(ts.timezone, date);
    const abbreviationNameString = `${abbreviation} (${ts.name})`;
    options.push(abbreviationNameString);
    VALID_DELAYED_PUBLICATION_TIMEZONES[index].abbreviation =
      abbreviationNameString;
  }
  return options;
}

export function GetAbbreviationForGivenTimeZone(timezone) {
  const currentDate = new Date();
  GetAllAbbreviationTimezonesForDate(currentDate);
  const abbreviation = getTimezoneAbbreviation(timezone, currentDate);
  // Check if the abbreviation exists
  let timezoneItem = VALID_DELAYED_PUBLICATION_TIMEZONES.find((tz) =>
    tz.abbreviation.includes(abbreviation)
  );
  let abbreviationName = "";
  if (typeof timezoneItem === "undefined") {
    // Does not exist so let's create it
    // Z
    const longForm = timezone; // getTimezoneLongFormat(browserTimeZone, currentDate);
    const split = longForm.split("/")[1];
    let name = longForm;
    abbreviationName = `${abbreviation} (${name})`;
    if (split.length > 0) {
      name = longForm.split("/")[1];
    }
    timezoneItem = {
      timezone: longForm,
      name: name,
      abbreviation: abbreviationName
    };
    VALID_DELAYED_PUBLICATION_TIMEZONES.push(timezoneItem);
  } else {
    abbreviationName = `${abbreviation} (${timezoneItem.name})`;
  }
  return abbreviationName;
}
export function GetAbbreviationForCurrentTimeZone() {
  const currentDate = new Date();
  GetAllAbbreviationTimezonesForDate(currentDate);
  const browserTimeZone = momentTS.tz.guess();
  const abbreviation = getTimezoneAbbreviation(browserTimeZone, currentDate);
  // Check if the abbreviation exists
  let timezoneItem = VALID_DELAYED_PUBLICATION_TIMEZONES.find((tz) =>
    tz.abbreviation.includes(abbreviation)
  );
  let abbreviationName = "";
  if (typeof timezoneItem === "undefined") {
    // Does not exist so let's create it
    // Z
    const longForm = browserTimeZone; // getTimezoneLongFormat(browserTimeZone, currentDate);
    const split = longForm.split("/")[1];
    let name = longForm;
    abbreviationName = `${abbreviation} (${name})`;
    if (split.length > 0) {
      name = longForm.split("/")[1];
    }
    timezoneItem = {
      timezone: longForm,
      name: name,
      abbreviation: abbreviationName
    };
    VALID_DELAYED_PUBLICATION_TIMEZONES.push(timezoneItem);
  } else {
    abbreviationName = `${abbreviation} (${timezoneItem.name})`;
  }
  return abbreviationName;
}

/*
  Given the stored DB value of EDT (US Eastern) return US/Eastern
 */
export function FormatDateForAPublicationGivenDelayedPublicationTimezone(
  datetime,
  timezone
) {
  datetime = ensureZPostfix(datetime);
  return moment.tz(datetime, timezone).format(REPORT_DEFAULT_DATE_FORMAT); // Since converting, it back to the javascript date using moment.toDate() will use the browsers timezone, formatting would then show the browsers timezone and not the original desired timezone
}

export function FormatDateForAScheduledChangePopoverGivenDelayedPublicationTimezone(
  datetime,
  timezone
) {
  datetime = ensureZPostfix(datetime);
  const inTimeZoneDate = moment.tz(datetime, timezone);
  return inTimeZoneDate.format("DD MMMM yyyy [at] HH:mm z"); // Since converting, it back to the javascript date using moment.toDate() will use the browsers timezone, formatting would then show the browsers timezone and not the original desired timezone
}

/*
  Given the timezone string "Pacific/Auckland", return "NZST" when the date is within normal standard time, "NSDT" when the date is within the daylight time
 */
function getTimezoneAbbreviation(timeZone, date) {
  const momentDateTime = moment.tz(date, timeZone);
  // Format the timezone abbreviation, z is for short form "NZST" and Z "Pacific/Auckland" is the long form
  return momentDateTime.format("z");
}

function getTimezoneLongFormat(timeZone, date) {
  const momentDateTime = moment.tz(date, timeZone);
  // Format the timezone abbreviation, z is for short form "NZST" and Z "Pacific/Auckland" is the long form
  return momentDateTime.format("Z");
}
export function ensureZPostfix(dateStr) {
  if (dateStr !== null && typeof dateStr != "undefined" && dateStr.length > 0) {
    if (!dateStr.endsWith("Z")) {
      return dateStr + "Z";
    }
  }
  return dateStr;
}

/*
  Creates a new date that has the time delta between the current timezone and the target time zone, to be used within the date-range component for scheduled publication
 */
export function getDateWithHourDifference(targetTimezone, forDate) {
  const currentOffset = momentTS().utcOffset();

  // Get the target tinezones offset
  const targetOffset = momentTS.tz(targetTimezone).utcOffset();

  // We need the difference in minutes
  const differenceInMinutes = targetOffset - currentOffset;
  forDate.setMinutes(forDate.getMinutes() + differenceInMinutes);
  return forDate;
}
