<template>
  <button
    v-ripple="20"
    class="button-action"
    :class="{
      'icon-only': iconOnly,
      'no-icon': !icon,
      primary: primary,
      loading: loading,
      success: success,
      small: small,
      'extra-small': extraSmall,
      'warning-hover': warningHover,
      'warning-hover-light': warningHoverLight,
      warning: warning,
      disabled: disabled,
      hovered: hovered,
      popup: popup,
      'save-cancel': saveCancel,
      submenu: submenu
    }"
    @click="handleClick"
  >
    <transition name="button-action-loading" mode="out-in">
      <div v-if="!internalLoading && !success" key="content" class="content">
        <transition v-if="icon" name="button-action-loading" mode="out-in">
          <div :key="icon" class="icon">
            <i :class="`${iconPack} ${iconPack}-${icon}`"></i>
            <i v-if="submenu" class="submenu las la-angle-down"></i>
          </div>
        </transition>
        <div v-if="!iconOnly" class="text">
          <slot></slot>
        </div>
      </div>
      <div v-else-if="success" key="success" class="text-loader-wrapper">
        <i class="mi-check"></i>
      </div>

      <div v-else-if="!iconOnly" key="loading" class="text-loader-wrapper">
        <loading-text-dots />
      </div>
      <div v-else key="loading-small" class="text-loader-wrapper">
        <circular-loader small />
      </div>
    </transition>
  </button>
</template>

<script>
import LoadingTextDots from "../loaders/LoadingTextDots";
import CircularLoader from "../loaders/CircularLoader";

export default {
  components: {
    "loading-text-dots": LoadingTextDots,
    "circular-loader": CircularLoader
  },
  props: {
    icon: {
      type: String,
      default: ""
    },
    iconOnly: {
      type: Boolean,
      default: false
    },
    primary: {
      type: Boolean,
      default: false
    },
    hovered: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    extraSmall: {
      type: Boolean,
      default: false
    },
    warning: {
      type: Boolean,
      default: false
    },
    warningHover: {
      type: Boolean,
      default: false
    },
    warningHoverLight: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    popup: {
      type: Boolean,
      default: false
    },
    iconPack: {
      type: String,
      default: "la"
    },
    saveCancel: {
      type: Boolean,
      default: false
    },
    submenu: {
      type: Boolean,
      default: false
    },
    delay: {
      type: String,
      default: "1500"
    }
  },

  data: () => ({
    internalLoading: false,
    timer: null,
    success: false
  }),

  watch: {
    loading: {
      immediate: true,
      async handler(val) {
        if (val) {
          await this.setWidth();
          this.internalLoading = true;
          clearTimeout(this.timer);
        } else {
          this.timer = setTimeout(() => {
            this.internalLoading = false;
          }, 500);
        }
      }
    }
  },

  async mounted() {},

  methods: {
    async handleClick() {
      this.$emit("click");
    },

    async setWidth() {
      if (this.$el) {
        const width = this.$el.offsetWidth;
        if (width) {
          this.$el.style.width = `${width}px`;
        } else {
          await new Promise((resolve) => setTimeout(resolve, 100));
          this.setWidth();
        }
        return;
      }
    },

    async showSuccess() {
      await this.setWidth();
      this.success = true;
      await new Promise((resolve) => setTimeout(resolve, this.delay));
      this.success = false;
      return true;
    }
  }
};
</script>

<style lang="sass" scoped>
@import "./style"

.button-action-loading-enter-active,
.button-action-loading-leave-active
  transition: all 0.2s ease-in-out

.button-action-loading-enter
  opacity: 0
  transform: translateY(-10px)

.button-action-loading-leave-to
  opacity: 0
  transform: translateY(10px)


.button-action
  @extend .button-action-style
  display: inline-flex !important
  &.no-icon
    .content
      grid-gap: 0px
      grid-template-columns: 1fr

  .content
    display: grid
    align-items: center
    justify-content: flex-start
    grid-template-columns: auto 1fr
    grid-gap: 8px
    flex: 1
    .submenu
      font-size: 6px

  .text-loader-wrapper
    display: flex
    align-items: center
    justify-content: center
    flex: 1

  &.small
    height: 25px
    padding: 0px 6px
    .content
      grid-gap: var(--gap-small)
    .text
      font-size: 11px
      padding-right: 0px !important
    i
      font-size: 14px

  &.extra-small
    height: 18px
    padding: 0px 6px
    .content
      grid-gap: var(--gap-small)
    .text
      font-size: 9px
      padding-right: 0px !important
    i
      font-size: 11px
      position: relative
      top: 0px

  &.icon-only
    width: var(--button-action-height)
    padding: 0px
    background-color: transparent !important
    &:hover
      background-color: var(--button-action-color-hover) !important
    .content
      display: flex
      align-items: center
      justify-content: center
    .text
      display: none
    i
      font-size: 18px
      color: rgba(var(--color-blue),1) !important
    &.disabled
      i
        color: rgba(0,0,0,0.3) !important

    &.small
      width: 25px !important
      i
        font-size: 14px !important

    &.extra-small
      width: 18px !important
      i
        font-size: 11px !important
        position: relative
        top: -1px

  &.primary
    background-color: rgba(var(--color-orange),1) !important
    .text
      color: #fff !important
    i
      color: #fff !important
    &:hover
      background-color: rgba(var(--color-blue),0.9) !important

  &.popup
    background-color: rgba(0,0,0,0.0)
    .text
      color: rgba(var(--color-blue),1)
    i
      color: rgba(var(--color-blue),.5)
    &:hover
      background-color: var(--button-action-color)
      i
        color: rgba(var(--color-blue),1)

  &.warning-hover-light
    &:hover
      background-color: rgba(var(--color-error),0.15)
      .text
        color: rgba(var(--color-error),1)
      i
        color: rgba(var(--color-error),1)

  &.warning-hover, .warning
    &:hover
      background-color: rgba(var(--color-error),1)
      .text
        color: #fff
      i
        color: #fff

  &.warning
    background-color: rgba(var(--color-error),1)
    .text
      color: #fff
    i
      color: #fff
    &:hover
      background-color: rgba(var(--color-error),0.9)

  &.disabled
    background-color: rgba(0,0,0,0.05)
    .text
      color: rgba(0,0,0,0.5)
    i
      color: rgba(0,0,0,0.3)

  &.loading, &.success, &.disabled
    pointer-events: none

  &.opaq
    opacity: .5

  &.save-cancel
    .content
      .text
        width: 50px
        text-align: center
</style>
