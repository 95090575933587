<template>
  <button
    ref="button"
    v-ripple="50"
    class="button-login"
    :class="buttonClasses"
    :style="buttonStyle"
    :disabled="disabled || loading"
    v-on="$listeners"
  >
    <transition mode="out-in" name="icon">
      <div v-if="!loading" class="text">
        <slot></slot>
      </div>
      <LoadingTextDots v-else />
    </transition>
  </button>
</template>

<script>
import LoadingTextDots from "~/components/new/LoadingTextDots";

export default {
  components: { LoadingTextDots },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    tonal: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    margins: {
      type: Boolean,
      default: true
    },
    icon: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    width: 0
  }),

  computed: {
    buttonStyle() {
      const out = {};
      const width = this.width
        ? this.loading
          ? "100%"
          : `${this.width}px`
        : "auto";

      if (!this.icon) {
        out.width = width;
      }

      return out;
    },
    buttonClasses() {
      return {
        tonal: this.tonal,
        small: this.small,
        margins: this.margins,
        loading: this.loading,
        disabled: this.disabled,
        icon: this.icon
      };
    }
  },

  mounted() {
    this.calculateWidth();
    window.addEventListener("resize", this.calculateWidth);
  },

  destroyed() {
    window.removeEventListener("resize", this.calculateWidth);
  },

  methods: {
    async calculateWidth() {
      this.width = 0;
      await this.$nextTick();
      if (this.$refs.button) this.width = this.$refs.button.offsetWidth + 5;
    }
  }
};
</script>

<style lang="sass" scoped>
.icon-enter-active, .icon-leave-active
  transition: all .2s ease-in-out

.icon-enter
  transform: translateY(-20px)
  opacity: 0
.icon-leave-to
  transform: translateY(20px)
  opacity: 0

.button-login
  height: 45px
  border-radius: 8px
  background-color: #ff7907
  padding: 0 34px
  display: inline-flex
  align-items: center
  justify-content: center
  color: white
  font-size: 14px
  font-weight: 600
  letter-spacing: 1px
  cursor: pointer
  transition: all 0.2s ease-in-out
  box-shadow: none
  border: none
  width: auto
  margin: 0 !important
  box-shadow: 0 0 0 -10px transparent
  width: auto
  .text
    transition: all 0.2s ease-in-out
    text-transform: capitalize
    white-space: nowrap
  &:hover:not(:disabled)
    transform: scale(1.03)
    box-shadow: 0 8px 15px -4px rgba(var(--color-primary), 0.5)
  &.tonal
    box-shadow: 0 0 0 -10px transparent
    background-color: rgba(255,255,255,0.1)
    &:hover:not(:disabled)
      background-color: rgba(255,255,255,0.2)
      box-shadow: 0 0 0 -10px transparent

  &.icon
    width: 45px
    padding: 0 !important
    &.small
      width: 35px
  &.disabled
    opacity: 0.5
    cursor: not-allowed

  &.loading
    cursor: wait
    width: 100%

  &.small
    height: 35px
    padding: 0 24px
    font-size: 12px
    font-weight: 600
    letter-spacing: 0.5px

  &.margins
    margin: 0 12px 12px 0
    &:last-child
      margin: 0
</style>
