<template>
  <div class="input-text-wrapper">
    <input-base
      class="input-text"
      :label="label"
      :has-value="hasValue"
      :is-focused="isFocused"
      :disabled="disabled || loading"
      :error="error"
      :success="success"
      :small="small"
      :dark="dark"
      :highlighted="highlighted"
      :character-limits="characterLimits"
      :input-text-length="modelValue?.length"
      @click.native="() => $refs?.input?.focus()"
      @dblclick.native="() => $refs?.input?.select()"
    >
      <template slot="prepend">
        <div v-if="icon" :key="icon">
          <i :class="`prepend-icon la la-${icon}`"></i>
        </div>
      </template>
      <input
        ref="input"
        autocomplete="off"
        :value="modelValue"
        :maxlength="characterLimits"
        tabindex="1"
        :disabled="disabled || success || loading"
        :type="typeComputed"
        :class="{ dark, error, disabled, success, small }"
        :pattern="type === 'number' ? '[0-9]*' : undefined"
        :name="computedName"
        @input="(e) => updateValue(e)"
        @focus="isFocused = true"
        @blur="isFocused = false"
        @keypress.enter="$emit('submit')"
      />

      <template slot="append">
        <div :key="clearable && hasValue">
          <button-action
            v-if="clearable && hasValue"
            icon="close"
            icon-only
            warning-hover-light
            small
            @click="modelValue = ''"
          />
        </div>
      </template>
    </input-base>
  </div>
</template>

<script>
import InputBase from "./InputBase.vue";
import ButtonAction from "../buttons/ButtonAction.vue";

export default {
  components: {
    "input-base": InputBase,
    "button-action": ButtonAction
  },

  props: {
    label: {
      type: String,
      default: "",
      required: true
    },
    value: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: "text"
    },
    error: {
      type: [String, Boolean],
      default: ""
    },
    success: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    pattern: {
      type: String,
      default: ""
    },
    small: {
      type: Boolean,
      default: false
    },
    dark: {
      type: Boolean,
      default: false
    },
    highlighted: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ""
    },
    characterLimits: {
      type: Number,
      default: null
    }
  },

  data: () => ({
    modelValue: "",
    isFocused: false,
    showPassword: false,
    isAutoFilled: false
  }),

  computed: {
    hasValue() {
      return !!this.modelValue;
    },
    isLabelUp() {
      return this.hasValue || this.isFocused || this.isAutoFilled;
    },
    typeComputed() {
      if (this.type === "password" && !this.showPassword) {
        return "password";
      }
      return this.type;
    },
    computedName() {
      return this.name || this.label || "placeholder-name";
    }
  },

  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.modelValue = value;
      }
    },

    modelValue: {
      immediate: true,
      handler(value) {
        if (value != this.value) {
          this.$emit("input", value);
          this.$emit("reset-error");
        }
      }
    },
    isFocused(value) {
      if (!value) {
        this.$emit("blur");
      }
    }
  },

  mounted() {
    if (this.type === "number") {
      window.addEventListener("keydown", this.onKeyDown);
    }
    this.$nextTick(async () => {
      this.isAutoFilled = await this.detectAutofill(this.$refs.input);
    });
  },
  beforeDestroy() {
    if (this.type === "number") {
      window.removeEventListener("keydown", this.onKeyDown);
    }
  },

  methods: {
    onKeyDown(event) {
      if (this.type === "number") {
        if (event.keyCode === 38 || event.keyCode === 40) {
          event.preventDefault();
        }
      }
    },
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
    updateValue(e) {
      let emitValue = e.target.value;
      if (this.type == "number") {
        emitValue = emitValue.replaceAll(/[^0-9]/g, "");
      }
      this.modelValue = emitValue;
    },
    detectAutofill(element) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(
            window
              .getComputedStyle(element, null)
              .getPropertyValue("appearance") === "menulist-button"
          );
        }, 600);
      });
    }
  }
};
</script>

<style lang="sass">
.input-text-wrapper
  margin: 0
  .input-text
    cursor: text
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button
      -webkit-appearance: none
      margin: 0
    input[type=number]
      -moz-appearance: textfield

    input
      width: 100%
      height: 100%
      padding: 0px !important
      line-height: 1 !important
      border-radius: 0
      box-shadow: none
      outline: none
      color: rgba(0,0,0,1)
      transition: all 0.2s ease-in-out
      font-size: var(--font-size-small)
      border: none
      font-weight: 400
      background-color: transparent
      padding: 0px !important
      margin: 0px !important
      position: relative
      top: -2px
      &.dark
        color: rgba(255,255,255,1)
        &.disabled
          color: rgba(255,255,255,0.5) !important
        &.success
          color: rgba(var(--color-success), 1)
      &.small
        font-size: var(--font-size-regular)
        padding: 0px 6px
      &.disabled
        color: rgba(0,0,0, .5) !important
      &.success
        color: rgba(var(--color-success), 1)
</style>
