<template>
  <div
    class="dialog"
    :class="{ active: modelState, [variant]: !!variant }"
    @click="(e) => clickOutside(e)"
  >
    <transition name="dialog">
      <div
        v-if="modelState"
        class="dialog-content"
        :class="{ shake: shake }"
        @click="(e) => clickOutside(e)"
      >
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    persistant: {
      type: Boolean,
      default: false
    },
    variant: {
      type: String,
      default: ""
    }
  },

  data: () => ({
    modelState: false,
    scrollBlocked: false,
    shake: false
  }),

  created() {},

  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (this.modelState != val) this.modelState = val;
      }
    },
    modelState: {
      handler(val) {
        this.$emit("input", val);
        if (val) {
          this.blockScroll();
          this.scrollBlocked = true;
        } else if (this.scrollBlocked) {
          this.unblockScroll();
        }
      },
      immediate: true
    }
  },

  methods: {
    clickOutside(e) {
      if (
        (e.target.classList.contains("dialog") ||
          e.target.classList.contains("dialog-content")) &&
        !this.persistant
      ) {
        this.modelState = false;
      } else if (
        (e.target.classList.contains("dialog") ||
          e.target.classList.contains("dialog-content")) &&
        this.persistant
      ) {
        this.$emit("persistantClickOutside");
        if (!this.shake) {
          this.shake = true;
          console.log("shake");
          setTimeout(() => {
            this.shake = false;
          }, 500);
        }
      }
    },
    blockScroll() {
      document.body.style.overflow = "hidden";
      document.body.style.paddingRight = "5px";
    },
    unblockScroll() {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0";
    }
  }
};
</script>

<style lang="sass">
@import "style.sass"
</style>

<style lang="sass" scoped>
.dialog
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  z-index: 2090
  background: rgba(0, 0, 0, 0.8)
  display: flex
  align-items: center
  justify-content: center
  opacity: 0
  pointer-events: none
  transition: all 0.3s ease-in-out
  transition-delay: 0.2s
  backdrop-filter: blur(5px)
  &.active
    opacity: 1
    pointer-events: all
    transition-delay: 0s

  .dialog-content
    width: 100%
    display: flex
    align-items: center
    justify-content: center
    padding: 2vh 2vw
    max-height: 90vh
    &.shake
      animation: shakeBig 0.38s cubic-bezier(.36,.07,.19,.97) both .12s
.dialog-enter-active, .dialog-leave-active
  transition: all .3s ease-in-out

.dialog-enter-active
  transition-delay: 0.2s

.dialog-enter
  transform: translateY(50vh)
  opacity: 0
.dialog-leave-to
  transform: translateY(50vh)
  opacity: 0
</style>
